/** Any string that contains an URL */
export const urlRegex =
  /https?:\/\/(www\.)?[-a-z0-9@:%._+~#=]{1,256}(\.[a-z0-9()]+|(:\d{1,5}))\b([-a-z0-9()@:%_+.~#?&//=!;]*)/gim;

/** Strictly a string that is only URL */
export const strictUrlRegex =
  /^https?:\/\/(www\.)?[-a-z0-9@:%._+~#=]{1,256}(\.[a-z0-9()]+|(:\d{1,5}))\b([-a-z0-9()@:%_+.~#?&//=!;]*)/i;

// NOTE: react-mentions markup format is @[display](id)
export const chatMentionRegex = /@\[(.+?)\]\((.+?)\)/gm;

/**
 * @TODO - This doesn't work if the string includes line breaks.
 *
 * For example, this is valid but doesn't match:
 *
 * ```
 *   <iframe
 *    src="https://www.example.com"
 *    width="200">
 *   </iframe>
 *  ```
 *
 * If your html is formatted like this you can replace all whitespaces with
 * spaces before using the regex, like this:
 *
 * ```
 *   const html = iframeWithLineBreaks.replace(/\s/g, " ");
 * ```
 */
export const iframeRegex =
  /<iframe\s.*?src=\s*["']\s*(https?:\/\/(www\.)?[-a-z0-9@:%._+~#=]{1,256}(\.[a-z0-9()]+|(:\d{1,5}))\b([-a-z0-9()@:%_+.~#?&//=!;\s]*)?)["'].*?>.*?<\/iframe>/gim;

export const htmlRegex = /<\/?[a-z][\s\S]*>/i;

export const userNameRegex = /^\s*(\p{L}{1,}([.,] |[-']| )?)+\p{L}*\.?\s*$/iu;

export const youtubeLinkRegex =
  /^(?:https?:\/\/)?(?:www\.)?(?:youtube(-nocookie)?\.com|youtu\.be)\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=)?([a-zA-Z0-9_-]{11})/;
