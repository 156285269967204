import type { RuntimeConfig } from "./runtimeConfig.types";

export enum Application {
  CollaBoardWeb = "CollaBoardWeb",
  CollaBoardBackOffice = "CollaBoardBackOffice",
}

export type Client = {
  application: Application;
  host: string;
};

/**
 * @NOTE - Uses a function rather than exporting the value directly so that the
 * value be mocked easily.
 */
export const getRuntimeConfig = (): RuntimeConfig => {
  const params = new URLSearchParams(window.location.search);
  return {
    ...window.runtimeConfig,
    features: {
      ...window.runtimeConfig.features,
      /**
       * @TODO #6645 - Temporary. To be reworked soon.
       */
      runForCapture: params.has("runForCapture") ? true : undefined,
    },
  };
};
