export const getAppVersion = (): string => {
  return `${process.env.REACT_APP_MAJORMINOR_VERSION}.${process.env.REACT_APP_BUILD_VERSION}`;
};

export const getVersionInfo = (): string => {
  const appVersion = getAppVersion();
  const appBuildCommit = process.env.REACT_APP_BUILD_COMMIT;

  return appBuildCommit ? `${appVersion} (${appBuildCommit})` : appVersion;
};

/**
 * Parse semver formatted version number.
 */
export const parseVersion = (version: string): number[] => {
  return version.split(".").map((value) => parseInt(value, 10));
};

/**
 * Determine if semver value `a` is less than semver value `b`.
 */
export const isVersionLessThan = (a: string, b: string): boolean => {
  const versionA = parseVersion(a);
  const versionB = parseVersion(b);

  const diffs = versionA.map((_, i) => {
    return versionA[i] - versionB[i];
  });

  const [major, minor, patch] = diffs;

  if (major < 0) {
    return true;
  } else if (major === 0 && minor < 0) {
    return true;
  } else if (major === 0 && minor === 0 && patch < 0) {
    return true;
  }

  return false;
};
