import { createRoot } from "react-dom/client";
import { isDevModeEnabled } from "./runtimeConfig/flags";
import { loadRuntimeConfig } from "./runtimeConfig/loader";
import { stringifyError } from "./tools/errors";

/**
 * Wait for runtimeConfig to be available before bootstrapping the app.
 * This allows us to use non-optional types for RuntimeConfig.
 */
loadRuntimeConfig()
  .then(() => import("./App"))
  .then(({ default: App }) => {
    const root = createRoot(document.getElementById("root")!); // eslint-disable-line @typescript-eslint/no-non-null-assertion
    root.render(<App />);
  })
  .catch((loadError) => {
    try {
      /**
       * @NOTE `isDevModeEnabled()` attempts to read the runtimeConfig, so if it
       * isn't available (because the app is under maintenance or misconfigured)
       * this call will also fail, and therefore we also need to catch it so we
       * don't end up with the white-screen-of-death.
       */
      if (isDevModeEnabled()) {
        // eslint-disable-next-line no-console
        console.error(loadError);
        return;
      }
    } catch (devModeCheckError) {
      // eslint-disable-next-line no-console
      console.error(devModeCheckError);
    }

    const errorMsg = encodeURIComponent(stringifyError(loadError));

    window.location.replace(`/failed-to-load-app.html?error=${errorMsg}`);
  });
